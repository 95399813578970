import React from 'react';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import { CollapsibleListColumns } from 'reaxl-molecules';
import { sendClick } from 'reaxl-analytics';
import { dealerCrawlPathDuck } from '../../ducks';

function DealerCrawlPathMakesContainer({
    items,
}) {
    const sortedItems = items && items.sort((a, b) => ((a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : -1));
    return !_isEmpty(sortedItems) && (
        <CollapsibleListColumns
            title="Car Dealers by Make"
            items={sortedItems}
            render={({ label, ...rest }) => (
                <a
                    className="text-link link-undecorated"
                    onClick={(event) => sendClick('sitemapFilterClick', event, { sitemapContent: event.target.innerText })}
                    {...rest}
                >
                    {label}
                </a>
            )}
            xs={2}
            md={4}
            lg={5}
        />
    );
}

function mapStateToProps(state) {
    const dealerCrawlPathData = dealerCrawlPathDuck.selectors.getDuckState(state);

    return {
        items: dealerCrawlPathData?.makes,
    };
}

export default connect(mapStateToProps)(DealerCrawlPathMakesContainer);
